import React, { useState, useEffect } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  useToast,
  Flex,
  Text,
  Breadcrumb,
  Spinner,
  BreadcrumbItem,
  RadioGroup,
  Stack,
  Select,
} from '@chakra-ui/react';
import ConfigImage from '../../components/ConfigImage';
import CredentialText from '../../components/CredentialText';
import FeatureSelect from '../../components/FeatureSelect';
import auth from '../../../../apis/auth';
import { AffiliateType, ConfigImageType, CredentialType } from '../../types';
import cms from '../../../../apis/cms';
import GeneralConfigValue from '../../components/GeneralConfigValue';
import {
  URL_HEADER1,
  URL_HEADER2,
  URL_HEADER3,
  URL_HEADER5,
  URL_HOME1,
  URL_HOME2,
  URL_PROMO1,
  URL_PROMO2,
  URL_PROMO3,
} from './url/IUrl';

interface IUser {
  id: string;
  roles: string[];
}

interface IVersions {
  id: string;
  name: string;
  Versions: number;
}

const Configuration: React.FC = () => {
  const [user, setUser] = useState<IUser>({ id: '', roles: [] });
  const toast = useToast();
  const [features, setFeatures] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [configImages, setConfigImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [urlHeader, setUrlHeader] = useState('');
  const [headerVersion, setHeaderVersion] = useState({
    id: '',
    name: '',
    Versions: 0,
  });
  const [urlhome, setUrlHome] = useState('');
  const [homeVersion, setHomeVersion] = useState({
    id: '',
    name: '',
    Versions: 0,
  });
  const [urlPromo, setUrlPromo] = useState('');
  const [promoVersion, setPromoVersion] = useState({
    id: '',
    name: '',
    Versions: 0,
  });
  useEffect(() => {
    async function getImages() {
      setLoading(true);
      try {
        const { data: apiUser } = await auth.get<any>('users/me');
        setUser(apiUser);
        const { data } = await cms.rest.get<any>('/config-images', {
          params: {
            withFile: true,
          },
        });
        setConfigImages(data);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    getImages();
  }, [toast]);

  useEffect(() => {
    async function getVersion() {
      const header: IVersions = (await cms.rest('/versions/headers'))
        .data as IVersions;

      const home: IVersions = (await cms.rest('/versions/home'))
        .data as IVersions;

      const promo: IVersions = (await cms.rest('/versions/promo'))
        .data as IVersions;

      setHeaderVersion(header);
      setHomeVersion(home);
      setPromoVersion(promo);
      if (header.Versions === 1) {
        setUrlHeader(URL_HEADER1);
      } else if (header.Versions === 2) {
        setUrlHeader(URL_HEADER2);
      } else if (header.Versions === 3) {
        setUrlHeader(URL_HEADER3);
      } else if (header.Versions === 4) {
        setUrlHeader(URL_HEADER3);
      } else if (header.Versions === 5) {
        setUrlHeader(URL_HEADER5);
      } else if (header.Versions === 6) {
        setUrlHeader(URL_HEADER5);
      }

      if (home.Versions === 1) {
        setUrlHome(URL_HOME1);
      } else if (home.Versions === 2) {
        setUrlHome(URL_HOME2);
      }

      if (promo.Versions === 1) {
        setUrlPromo(URL_PROMO1);
      } else if (promo.Versions === 2) {
        setUrlPromo(URL_PROMO2);
      } else if (promo.Versions === 3) {
        setUrlPromo(URL_PROMO3);
      }
    }

    getVersion();
  }, []);

  async function updteHeader(
    event: React.ChangeEvent<HTMLSelectElement>,
    header: IVersions,
  ) {
    const { value } = event.target;

    let url = '';

    if (value === '1') {
      url = URL_HEADER1;
    } else if (value === '2') {
      url = URL_HEADER2;
    } else if (value === '3') {
      url = URL_HEADER3;
    } else if (value === '4') {
      url = URL_HEADER3;
    } else if (value === '5') {
      url = URL_HEADER5;
    } else if (value === '6') {
      url = URL_HEADER5;
    }

    const updatedHeader = (
      await cms.rest.put(`versions/${header.id}`, {
        name: 'headers',
        Versions: Number(value),
      })
    ).data as IVersions;

    setHeaderVersion({
      id: header.id,
      name: updatedHeader.name,
      Versions: updatedHeader.Versions,
    });
    setUrlHeader(url);
  }

  async function updteHome(
    event: React.ChangeEvent<HTMLSelectElement>,
    home: IVersions,
  ) {
    const { value } = event.target;

    let url = '';

    if (value === '1') {
      url = URL_HOME1;
    } else if (value === '2') {
      url = URL_HOME2;
    }
    const updatedhome = (
      await cms.rest.put(`versions/${home.id}`, {
        name: 'home',
        Versions: Number(value),
      })
    ).data as IVersions;

    setHomeVersion({
      id: updatedhome.id,
      name: updatedhome.name,
      Versions: updatedhome.Versions,
    });
    setUrlHome(url);
  }

  async function updtePromo(
    event: React.ChangeEvent<HTMLSelectElement>,
    promo: IVersions,
  ) {
    const { value } = event.target;

    let url = '';

    if (value === '1') {
      url = URL_PROMO1;
    } else if (value === '2') {
      url = URL_PROMO2;
    } else if (value === '3') {
      url = URL_PROMO3;
    }
    const updatedPromo = (
      await cms.rest.put(`versions/${promo.id}`, {
        name: 'promo',
        Versions: Number(value),
      })
    ).data as IVersions;

    setPromoVersion({
      id: updatedPromo.id,
      name: updatedPromo.name,
      Versions: updatedPromo.Versions,
    });
    setUrlPromo(url);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleRequests(index: number): Promise<void> {
    try {
      setLoading(true);
      if (index === 1 || index === 2) {
        const { data } = await auth.get<any>('affiliates', {
          params: {
            withCredentials: true,
          },
        });
        setAffiliates(data);
      }
      if (index === 2) {
        const { data } = await auth.get<any>('features');
        setFeatures(data);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Box
      p={8}
      w="100%"
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <Heading mb="24px" color="#469BFF" fontFamily="Poppins">
        Configurações
      </Heading>
      <Flex
        flexDir="column"
        bg="#F9F9F9"
        p={8}
        w="100%"
        borderRadius={6}
        boxShadow="lg"
      >
        <Tabs
          onChange={index => handleRequests(index)}
          marginLeft="1px"
          variant="line"
        >
          <TabList>
            <Tab>Geral</Tab>
            <Tab>Features</Tab>
            {user.roles[0] === 'sysadmin' ? <Tab>Providers</Tab> : <></>}
          </TabList>

          <TabPanels mb={7}>
            <TabPanel>
              <RadioGroup
                value={String(headerVersion.Versions)}
                width="10%"
                margin="0 0 -55px 0"
                paddingLeft="30px"
              >
                <Stack spacing={3}>
                  <Select
                    placeholder="Header"
                    defaultValue={headerVersion.Versions}
                    size="xs"
                    onChange={async event => {
                      await updteHeader(event, headerVersion);
                    }}
                  >
                    <option value="1">Chumbo</option>
                    <option value="2">Branco</option>
                    <option value="3">Cinza</option>
                    <option value="4">Cinza Oculto</option>
                    <option value="5">Amarelo Oculto</option>
                    <option value="6">Amarelo</option>
                  </Select>
                </Stack>
              </RadioGroup>
              <Flex flexDir="row" w="fit-content" p={8} overflow="auto">
                <ConfigImage
                  title=""
                  nameImage="Header"
                  key={headerVersion.id}
                  url={urlHeader}
                />

                <Box>
                  <Stack spacing={3}>
                    <Select
                      width="7rem"
                      placeholder="Home"
                      defaultValue={homeVersion.Versions}
                      size="xs"
                      onChange={async event => {
                        await updteHome(event, homeVersion);
                      }}
                    >
                      <option value="1">Home Padrão</option>
                      <option value="2">Home Black Friday</option>
                    </Select>
                  </Stack>
                  <ConfigImage
                    title=""
                    nameImage="Header"
                    key={homeVersion.id}
                    url={urlhome}
                  />
                </Box>

                <Box>
                  <Stack spacing={3}>
                    <Select
                      width="7rem"
                      placeholder="status promocional"
                      defaultValue={promoVersion.Versions}
                      size="xs"
                      onChange={async event => {
                        await updtePromo(event, promoVersion);
                      }}
                    >
                      <option value="1">Default</option>
                      <option value="2">Pre Black Friday</option>
                      <option value="3">Black Friday</option>
                    </Select>
                  </Stack>
                  <ConfigImage
                    title=""
                    nameImage="Header"
                    key={promoVersion.id}
                    url={urlPromo}
                  />
                </Box>

                {configImages.map((configImage: ConfigImageType) => (
                  <ConfigImage
                    title={configImage.name}
                    key={configImage.id}
                    nameImage={configImage.name}
                    url={configImage?.file?.url}
                  />
                ))}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex display="-ms-inline-flexbox" w="1080px" h="720px">
                <Flex width="650px">
                  <Box width="285px">
                    <GeneralConfigValue
                      label="Regra frete"
                      name="free_shipping_value"
                      type="number"
                    />
                  </Box>
                  <Box width="285px" ml="50px">
                    <GeneralConfigValue
                      label="Cupom desconto"
                      name="discount_coupon"
                      type="text"
                    />
                  </Box>
                </Flex>
                <Flex
                  marginTop="40px"
                  w="1080px"
                  h="90%"
                  display="flex"
                  flexWrap="wrap"
                >
                  {features ? (
                    features.map(
                      (feature: {
                        id: string;
                        name: string;
                        affiliate_id: string;
                      }) => (
                        <Box width="285px" height="80px" marginRight="50px">
                          <FeatureSelect
                            title={feature.name}
                            options={affiliates}
                            selectValue={feature.affiliate_id}
                          />
                        </Box>
                      ),
                    )
                  ) : (
                    <Text>Não foi encontrado nenhuma feature disponível</Text>
                  )}
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDir="column" w="fit-content" p={8} overflow="auto">
                {affiliates.map((affiliate: AffiliateType) => (
                  <div key={affiliate.id}>
                    <Flex>
                      <Breadcrumb
                        separator=""
                        width="100%"
                        alignItems="flex-start"
                        sx={{
                          '.Pai:hover': {
                            '.teste': {
                              opacity: '1 !important',
                            },
                            '.Texto': {
                              transform: 'translateY(-20px) !important',
                            },
                          },
                        }}
                      >
                        {affiliate.credentials.length > 0 ? (
                          affiliate.credentials.map(
                            (credential: CredentialType) => (
                              <BreadcrumbItem
                                margin="0 10px 10px 0"
                                height="12rem"
                                width="10rem"
                                backgroundColor="#FFFFFF"
                                justifyContent="center"
                                className="Pai"
                              >
                                <Box>
                                  <Heading
                                    textAlign="center"
                                    pt="20px"
                                    size="lg"
                                    className="Texto"
                                    transition="all 0.3s ease"
                                    transform="translateY(20%)"
                                  >
                                    {affiliate.name.toUpperCase()}
                                    <Text fontSize="1rem" textColor="gray.500">
                                      {credential.slug}
                                    </Text>
                                  </Heading>
                                  <CredentialText
                                    style={{
                                      opacity: '0',
                                      transition: 'all 0.3s ease',
                                    }}
                                    className="teste"
                                    key={credential.slug}
                                    credential={credential}
                                    affiliate={affiliate}
                                  />
                                </Box>
                              </BreadcrumbItem>
                            ),
                          )
                        ) : (
                          <Text>
                            Sem credenciais cadastradas para esse provider
                          </Text>
                        )}
                      </Breadcrumb>
                    </Flex>
                  </div>
                ))}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
        {loading && (
          <Flex
            position="absolute"
            align="center"
            justify="center"
            top="50%"
            left="50%"
          >
            <Spinner color="brand.500" size="xl" />
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Configuration;
